import React, { createContext, useReducer } from 'react';
import actions from './actions';

const initialState = {
  ebookEmail: '',
  contactForm: {
    contactName: '',
    contactEmail: '',
    contactMessage: '',
    interestOneOnOne: false,
    interestGroup: false,
    interestWorkshop: false,
    messageSent: false,
    error: false
  }
};

const store = createContext(initialState);
const { Provider, Consumer } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    let updatedContactForm;
    switch (action.type) {
      case actions.UPDATE_CONTACT_FORM:
        updatedContactForm = { ...state.contactForm };
        updatedContactForm[action.payload.key] = action.payload.value;
        return {
          ...state,
          contactForm: updatedContactForm
        };

      case actions.UPDATE_CONTACT_FORM_MULTIPLE:
        updatedContactForm = { ...state.contactForm };
        action.payload.forEach((field) => {
          updatedContactForm[field.key] = field.value;
        });
        return {
          ...state,
          contactForm: updatedContactForm
        };

      case actions.UPDATE_EBOOK_EMAIL:
        return {
          ...state,
          ebookEmail: action.payload
        };
      default:
        return state;
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { actions, store, StateProvider, Consumer };
